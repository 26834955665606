
import Page from './Page.js';

/**
 * @typedef
 * @name PageOptions
 * @type Object
 * @param {String} page_id -
 * @param {PageClass} page_class -
 * @param {String} template -
 * @param {String} template_filename -
 * @param {Object} options -
 */


/**
 * Hou track van Pages (routes), Hou track van containers/frames, Laai pages in containers in.
 * @class GrapePages
 */
class GrapePages {
	/**
	 * @constructor
	 */
	constructor(Grape) {
		this.Grape = Grape;

		/**
		 * List of page definitions
		 * @memberof GrapePages
		 */
		this.pages = [];

		/**
		 * List of loaded pages
		 */
		this.loaded_pages = [];
	}

	/**
	 * Registers an array of pages
	 * @memberof GrapePages
	 * @param {Array} pages - An array of pages to be registered.
	 */
	registerPages(pages){
		for (let page of pages){
			this.register(page);
		}
	}

	/**
	 *  Registers a page by associating its route with the Grape routing system.
	 * @memberof GrapePages
	 *  @param {Object} page - The page object to register.
	 *  @param {string} page.route - The route to associate with the page.
	 */
	register(page){
		window.Grape.route(page.route, page);
	}

	/**
	 * Loads a page into element
	 * @memberof GrapePages
	 * @param {GrapeUI~Page} page_class -
	 * @param {DOMElement} element - Load page into DOMelement
	 * @param {Object} bindings -
	 * @param {Object} options -
	 * @param {Boolean} options.persist -
	 * @param {Callback} next -
	 */
	load_page(page, element, bindings, params, options, next) {
		// Remove previous page from element
		let existing_page = this.find_page_instance_by_element(element);
		let teardown_promise = null;

		async function f() {
			if (existing_page)
			{
				console.debug('Unloading existing page from element', existing_page);
				teardown_promise = existing_page.page.teardown(existing_page.instance, element);
			}
			if (!teardown_promise || !teardown_promise.then)
				teardown_promise = Promise.resolve();

			await teardown_promise;

			await window.Grape.plugins.triggerPluginHooks('beforePageLoad', [
				page, element, bindings, params, options
			]);
			let p = page.load(element, bindings, params, options);
			let instance = null;
			try
			{
				instance = await p;
			} catch (err)
			{
				console.error('Error while loading page:', err);
				if (next && next instanceof Function)
					return next(err);
				else
					throw err;
			}

			window.Grape.update_visible_roles();
			window.Grape.autobind(element, instance);

			await window.Grape.plugins.triggerPluginHooks('afterPageLoad', [
				instance, element, options
			]);

			window.Grape.emit('page_loaded', page, instance);

			if (next && next instanceof Function)
				return next(null, instance);
			else
				return instance;
		}

		return f();
	}


	/**
	 * Adds a new page to the list
	 * @memberof GrapePages
	 * @param {PageOptions} options
	 */
	add_page(options) {
		let page = new Page({
			page_id: options.page_id,
			_class: options.page_class || options._class || null,
			template: options.template || null,
			template_filename: options.template_filename || null,
			options: options
		});

		this.pages.push(page);
		return page;
	}

	/**
	 * Find page by id
	 * @memberof GrapePages
	 */
	find_page_by_id(page_id) {
		return this.pages.find(function (a) { return (a.page_id == page_id); });
	}

	/**
	 * Find and return the page instance currently loaded into element
	 * @memberof GrapePages
	 * @param {DOMElement} element -
	 */
	find_page_instance_by_element(element) {
		let i = 0;
		for (i in this.pages)
		{
			let pg = this.pages[i];
			let r = pg.get_instance_by_element(element);
			if (r)
				return { page: pg, instance: r };
		}
		return null;
	}
}

export default GrapePages;

