import RouteDefinition from './RouteDefinition.js';
import RouteMatch from './RouteMatch.js';
import * as RouteUtils from './RouteUtils.js';

/**
 * @typedef NavigationTarget
 * @param {string} name The named route to navigate to
 * @param {string} hash The hash to navigate to
 * @param {*} parameters
 * @param {*} force_update
 */


/**
 * @class GrapeRouter
 */
export default class GrapeRouter {
	/**
	 * @constructor
	 */
	constructor(options) {
		this.options = options ?? {};

		this.__is_listening = false;
		this.__root_node = new RouteDefinition({ path: '/', node_type: 'literal', hash: '/' });
		this.__named_routes = {};
		this.current_route = null;

		// Needed for add / remove eventlistener to reference the same instance
		this.__hash_listener_func = this.hash_change_listener.bind(this);
		this.__hash_listener_options = {
			passive: true
		};
	}

	/**
	 * Event handler that triggers on the window.hashchange event if this router is listening for it
	 * @memberof GrapeRouter
	 * @param {object} event Event arguments created by the hashchange event
	 */
	async hash_change_listener(event) {
		let hash = unescape(RouteUtils.get_current_window_hash());

		let current_hash = this.current_route?.hash ?? null;
		if (hash !== current_hash)
		{
			RouteUtils.log_debug('hash changed. navigating to: ', hash, ' from ', current_hash ?? '');
			await this.call_route(RouteMatch.match(this.__root_node, hash));
		}
	}

	/**
	 * @memberof GrapeRouter
	 * @property is_listening
	 */
	get is_listening() { return this.__is_listening; }

	/**
	 * Start listening for hash changes
	 * @memberof GrapeRouter
	 */
	start() {
		if (!this.__is_listening && typeof (window.addEventListener) === 'function')
		{
			if (typeof (window.addEventListener) === 'function')
			{
				window.addEventListener('hashchange', this.__hash_listener_func, this.__hash_listener_options);
				this.__is_listening = true;
				RouteUtils.log_info('Grape Router started listening');
			}
			this.__hash_listener_func();
		}
	}

	/**
	 * Stop listening for hash changes
	 * @memberof GrapeRouter
	 */
	stop() {
		if (this.__is_listening && typeof (window.removeEventListener) === 'function')
		{
			if (typeof (window.removeEventListener) === 'function')
			{
				window.removeEventListener('hashchange', this.__hash_listener_func, this.__hash_listener_options);
				this.__is_listening = false;
				RouteUtils.log_info('Grape Router stopped listening');
			}
		}
	}

	/**
	 * Registers a new route on the router
	 * @memberof GrapeRouter
	 * @param {string} hash The hash to register
	 * @param {object} options The options object to assign to the newly added route
	 * @returns {RouteDefinition} The newly added route, or null if it couldn't be added
	 */
	add_route(hash, options) {
		let route;
		let node_list = RouteUtils.parse_route(hash, false);
		if (node_list)
		{
			const clean_hash = RouteUtils.build_route_string(node_list, true, false);

			if (node_list.length > 1)
			{
				// Register other nodes
				route = this.__root_node.add_children(node_list, options);
			}
			else if (node_list.length === 1 && node_list[0].path === '/' && node_list[0].node_type === 'literal')
			{
				// Register root node definition
				route = this.__root_node.define(options);
			}
			else
			{
				// Something went wrong
				RouteUtils.log_warn('Unable to add route:', clean_hash);
				return null;
			}

			if (route)
			{
				RouteUtils.log_debug('Route Added:', clean_hash);

				// Register the route by name
				const route_name = route?.name?.toLowerCase();
				if (route_name)
				{
					// Make sure it is not already in use
					if (this.__named_routes[route_name])
						RouteUtils.log_warn('Named route (', route_name, ') cannot be set to', route.hash, 'as it is already assigned to', this.__named_routes[route_name]);
					else
						this.__named_routes[route_name] = route.hash;
				}
			}
		}

		return route ?? null;
	}

	/**
	 * Attempt to navigate to the provided route
	 * @memberof GrapeRouter
	 * @private
	 * @param {RouteMatch} match A route match to attempt to navigate to
	 * @param {boolean} force_update Flag to force an update even if parameters didn't change
	 */
	async call_route(match, force_update) {
		let hash_changed = false;
		if (match)
		{
			let guard_result = await this.guard_navigation(this.current_route, match, force_update);

			if (guard_result === true)
			{
				RouteUtils.log_debug('Routing to:', match?.route?.hash + RouteUtils.build_parameter_string(match?.parameters));
				hash_changed = true;
				this.setHash(match?.hash); // Ensure the hash is set properly
				this.current_route = match;
			}
			else if (typeof guard_result === 'string')
			{
				RouteUtils.log_debug('Redirecting to:', guard_result, RouteUtils.build_parameter_string(match?.parameters));
				hash_changed = true;
				this.navigate(guard_result, null, match.parameters);
			}
		}

		if (!hash_changed && this.current_route?.hash)
		{
			this.setHash(this.current_route?.hash);
		}
	}

	/**
	 * Checks if the route should change, if the page instance should be updated and calls the necessary event handlers
	 * @memberof GrapeRouter
	 * @private
	 * @param {RouteMatch} source Route being navigated from
	 * @param {RouteMatch} target Route being navigated to
	 * @param {boolean} force_update Flag to force an update even if parameters didn't change
	 * @returns {(boolean|string)} boolean whether to allow a hash change, or a new hash to redirect to
	 */
	async guard_navigation(source, target, force_update) {
		const source_route = source?.route;
		const target_route = target?.route;

		let combined_source_bindings = this.mix_bindings_and_params(source?.bindings, source?.parameters);
		let combined_target_bindings = this.mix_bindings_and_params(target?.bindings, target?.parameters);

		let guard_result = false;

		// The route changes if the source is different from the target, or if the bindings changed
		let change_route = (source_route?.hash !== target_route?.hash) || (JSON.stringify(source?.bindings) !== JSON.stringify(target?.bindings));
		let update_route = !change_route && (JSON.stringify(source?.parameters) !== JSON.stringify(target?.parameters));

		if (change_route)
		{
			let leave_nodes = [];
			let enter_nodes = [];
			let common_ancestry = true;

			for (let idx = 0; idx < target?.route_list?.length || idx < source?.route_list?.length; idx++)
			{
				let check_src = (source?.route_list ?? [])[idx];
				let check_tgt = (target?.route_list ?? [])[idx];

				// Find nodes to enter and leave
				if (common_ancestry && idx < (target_route?.parent_depth ?? 0))
				{
					// Skip this node if it is a common ancestor
					if (check_src?.hash === check_tgt?.hash) continue;
					else common_ancestry = false;
				}

				// Find nodes to enter and leave
				if (check_src?.is_defined === true) leave_nodes.push(check_src);
				if (check_tgt?.is_defined === true) enter_nodes.push(check_tgt);
			}

			// Nodes need to be unloaded in reverse order
			leave_nodes.reverse();

			guard_navigation:
			{
				// Verify beforeRouteLeave
				for (let node_idx = 0; node_idx < leave_nodes.length; node_idx++)
				{
					const from = leave_nodes[node_idx];
					guard_result = await from?.__route?.beforeRouteLeave(target, source);
					if (guard_result !== true)
					{
						RouteUtils.log_debug('Guard beforeRouteLeave:', guard_result);
						return guard_result;
					}
				}

				// Verify beforeRouteEnter
				for (let node_idx = 0; node_idx < enter_nodes.length; node_idx++)
				{
					const to = enter_nodes[node_idx];
					guard_result = await to?.__route?.beforeRouteEnter(target, source);
					if (guard_result !== true)
					{
						RouteUtils.log_debug('Guard beforeRouteEnter:', guard_result);
						return guard_result;
					}
				}
			}

			// Call Teardown for each left node
			await Promise.all(leave_nodes.map(node => node?.__route?.teardown(node, combined_source_bindings, source?.parameters)));

			// Call Setup for each entered node
			await Promise.all(enter_nodes.map(node => node?.__route?.setup(node, combined_target_bindings, target?.parameters)));

			guard_result = true;
		}
		else if (update_route || force_update)
		{
			guard_result = await target?.route?.__route?.beforeRouteUpdate(target, source);
			if (guard_result !== true)
			{
				RouteUtils.log_debug('Guard beforeRouteUpdate:', guard_result);
				return guard_result;
			}

			target_route?.__route?.load(target_route, combined_target_bindings, target?.parameters);
			guard_result = true;
		}

		return guard_result;
	}

	mix_bindings_and_params(bindings, params) {
		let combined = {};
		Object.assign(combined, params);
		Object.assign(combined, bindings);

		return combined;
	}

	/**
	 * Sets the window hash to the given value
	 * @memberof GrapeRouter
	 * @private
	 * @param {string} hash The hash to navigate to
	 */
	setHash(hash) {
		// TODO: Sanitize hash?
		window.location.hash = hash;
	}


	/**
	 * Navigate to the specified URL with the provided options
	 * @memberof GrapeRouter
	 * @param {(string|NavigationTarget)} target
	 * @param {object} bindings Key/Value pairs to override the bindings in the hash with. Leave null for auto
	 * @param {object} parameters Key/Value pairs to overide the bindings in the new hash with. Leave null for auto
	 * @param {boolean} force_update  Flag to force an update even if parameters didn't change,
	 */
	navigate(target, bindings, parameters, force_update) {
		let match;
		if (typeof (target) === 'string')
		{
			match = RouteMatch.match(this.__root_node, target, bindings);
		}
		else if (typeof (target) === 'object')
		{
			let hash = target?.name;
			if (target?.name?.length > 0)
				hash ??= this.__named_routes[target?.name?.toLowerCase()];

			// Override parameters with values from the target object if present
			bindings = target?.bindings ?? bindings ?? null;
			parameters = target?.parameters ?? parameters ?? null;
			force_update = target?.force_update ?? force_update;

			match = RouteMatch.match(this.__root_node, hash, bindings);
		}

		// Replace the calculated parameters with the ones provided, if any
		if (match && parameters)
		{
			match.parameters = parameters;
			match.hash = match.hash.split('?', 2)[0] + RouteUtils.build_parameter_string(parameters);
		}

		this.call_route(match, force_update ?? false);
	}
}
